// Variables
$primary-color: #ff5a00
$secondary-color: #FF8A00
$font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
$padding-mobile: 20px
$padding-desktop: 50px 60px
$container-bg-color: #fcfcfc
$container-color: #fcfcfc

// Mixins
@mixin responsive-font-size($size-desktop, $size-mobile)
  font-size: $size-desktop
  @media (max-width: 991px)
    font-size: $size-mobile

@mixin responsive-padding($padding-desktop, $padding-mobile)
  padding: $padding-desktop
  @media (max-width: 991px)
    padding: $padding-mobile

@mixin button-styles($bg-color, $hover-color)
  border-radius: 20px
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
  background-color: $bg-color
  display: flex
  justify-content: space-between
  padding: 24px 33px
  text-decoration: none
  border: 0px
  &:hover
    background-color: $hover-color
    cursor: pointer
  &:touch
    background-color: $hover-color
    cursor: pointer
  @media (max-width: 991px)
    padding: 20px 33px

.button
  border-radius: 20px
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
  background-color: $primary-color
  display: flex
  gap: 20px
  justify-content: space-between
  padding: 24px 33px
  margin-top: 29px
  text-decoration: none
  border: 0px

.button:hover
  background-color: $secondary-color
  cursor: pointer

.button:touch
  background-color: $secondary-color
  cursor: pointer

@media (max-width: 991px)
  .button
    padding: 20px 33px

.button-icon
  width: 22px

.button-text
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  margin: auto
  font-size: 1rem
  text-decoration: none
  color: #fff

@media (max-width: 991px)
  .button-text
    font-size: 0.8rem

.container
  align-items: center
  background-color: $container-bg-color
  display: flex
  font-size: 23px
  color: #fcfcfc
  font-weight: 500
  justify-content: center
  padding: 50px 60px

@media (max-width: 991px)
  .container
    padding: 0 20px

.content-wrapper
  display: flex
  width: 531px
  max-width: 100%
  flex-direction: column

.logo
  // aspect-ratio: 3.45
  object-fit: auto
  object-position: center
  width: 100%
  height: auto

@media (max-width: 991px)
  .logo
    max-width: 100%

.button-container
  border-radius: 30px
  border: 1px solid rgba(167, 167, 167, 1)
  background-color: #fff
  display: flex
  margin-top: 10px
  flex-direction: column
  padding: 20px 62px 40px 
  justify-content: space-between
  margin-bottom:20px
  width: auto

@media (max-width: 991px)
  .button-container
    max-width: 100%
    margin-top: 10px
    padding: 20px 30px 40px 

.title
  color: #3a3a3a
  font-family:  Montserrat, -apple-system, Roboto, Helvetica, sans-serif
  font-size: 45px

@media (max-width: 991px)
  .title
    margin-right: 10px
    font-size: 40px

.authorized-access-title
  color: #3a3a3a
  font-family:  Montserrat, -apple-system, Roboto, Helvetica, sans-serif
  font-size: 1.8em

@media (max-width: 991px)
  .authorized-access-title
    margin-right: 10px
    font-size: 1.4em

.subtitle
  color: #646464
  margin-top: 10px
  font: 20px Montserrat, -apple-system, Roboto, Helvetica, sans-serif

// @media ( max-width: 991px )
//   .subtitle
//     padding-left: 20px
  

.visually-hidden
  position: absolute
  width: 1px
  height: 1px
  padding: 0
  margin: -1px
  overflow: hidden
  clip: rect(0, 0, 0, 0)
  white-space: nowrap
  border: 0

.form-title
  color: #3a3a3a
  font: 600 48px Montserrat, -apple-system, Roboto, Helvetica, sans-serif
  margin-top: 12px

@media (max-width: 991px)
  .form-title
    font-size: 40px

.form-input
  background-color: #f1f1f1
  border: 1px solid rgba(223, 223, 223, 1)
  border-radius: 9px
  color: #a8a8a8
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  line-height: 85%
  margin-top: 9px
  padding: 12px 10px
  font-size: 1rem
  
.form-input:focus
  border: 1px solid $secondary-color !important
  color: #3a3a3a

.form-input:focus-visible
  border: 1px solid #ffa500 !important
  color: #3a3a3a

.form-input:active
  border: 1px solid #ffa500 !important
  color: #3a3a3a

@media (max-width: 991px)
  .form-input
    white-space: initial

.form-button
  border-radius: 20px
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
  background-color: $primary-color
  display: flex
  justify-content: space-between
  padding: 18px 33px
  margin-top: 29px
  margin-bottom: 10px
  text-decoration: none
  border: 0px
  line-height: 85%

.form-button:hover
  background-color: $secondary-color

.form-button:touch
  background-color: $secondary-color

@media (max-width: 991px)
  .form-button
    padding: 18px 33px

.form-button-text
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  margin: 0px auto 0px
  font-size: 18px
  text-decoration: none
  color: #fff
  padding: 0px

.send-code-text
  color: #3a3a3a
  margin-top: 20px
  font: 14px Montserrat, -apple-system, Roboto, Helvetica, sans-serif

.resend-code-text
  color: #838383
  margin-top:20px
  font: 14px Montserrat, -apple-system, Roboto, Helvetica, sans-serif

.error-message
  color: red
  font-size: 12px
  margin-top: 5px
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif

.verification-code-input
  background-color: #f1f1f1
  border: 1px solid $primary-color
  border-radius: 9px
  color: #fd8b09
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  line-height: 85%
  margin-top: 9px
  padding: 12px 10px
  font-size: 24px
  height: auto
  letter-spacing: 30px
  text-align: center
  align-content: center

.verification-code-input.focus-visible
  border: 1px solid #ff9d00


// Código referente al módulo "Authorized Access"

.door-access-list
  margin-top: 20px

@media (max-width: 991px)
  .door-access-list
    margin-top: 5px

.door-access-row
  gap: 20px
  display: flex

@media (max-width: 991px)
  .door-access-row
    flex-direction: column
    align-items: stretch
    gap: 40px

.door-access-column
  display: flex
  flex-direction: column
  line-height: normal
  width: 50%
  margin-left: 0px

@media (max-width: 991px)
  .door-access-column
    width: 100%
    align-items: center

.add-margin-top
  margin-top: 20px

@media (max-width: 991px)
  .door-access-content
    white-space: initial
    padding: 0 20px

.door-access
  background: linear-gradient(180deg, #e4e2df 0%, #fcfcfc 100%)
  justify-content: center
  border-radius: 16px
  border: 2px solid $primary-color
  display: flex
  flex-grow: 1
  flex-direction: column
  font-size: 12px
  font-weight: 600
  white-space: initial
  align-items: center
  line-height: 167%
  width: 100%
  padding: 30px 40px
  cursor: pointer
  transition: background 0.3s ease-out, opacity 0.3s ease-out

.door-access-pressed
  justify-content: center
  border-radius: 16px
  border: 2px solid $primary-color
  background: linear-gradient(180deg, #FF5A00 0%, #FFA215 100%)
  display: flex
  flex-grow: 1
  flex-direction: column
  font-size: 12px
  font-weight: 600
  white-space: initial
  align-items: center
  line-height: 167%
  width: 100%
  padding: 30px 40px
  cursor: pointer
  transition: background 3s ease-out, opacity 3s ease-out
  
@media (max-width: 991px)
  .door-access
    width: 60%
    align-items: center

  .door-access-pressed
    width: 60%
    align-items: center

.door-name
  color: $primary-color
  font-family: Montserrat, sans-serif
  font-size: 14px
  font-weight: 700
  transition: color 0.3s ease-out

.door-name-pressed
  color: #fcfcfc !important
  font-family: Montserrat, sans-serif
  font-size: 14px
  font-weight: 700
  transition: color 3s ease-out

.door-alias
  color: #424242
  font-family: Montserrat, sans-serif
  margin-top: 8px
  transition: color 0.33s ease-out

.door-alias-pressed
  color: #ffffff !important
  font-family: Montserrat, sans-serif
  margin-top: 8px
  transition: color 3s ease-out

.door-image
  object-position: center
  font-size: 35px
  color: #ff5a00
  transition: color 0.3s ease-out

.door-image-pressed
  object-position: center
  font-size: 35px
  color: #fcfcfc !important
  transition: color 3s ease-out
  
.door-info
  justify-content: center
  display: flex
  margin-top: 12px
  flex-direction: column

@media (max-width: 991px)
  .door-info
    white-space: initial

#nav-bar
  align-self: center
  border-radius: 89px
  gap: 0px
  color: #ffffff
  background-color: #ff5a00
  font-family: Montserrat, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif
  vertical-align: middle
  border-color: #ff5a00

  .nav-bar-text
    color: #ffffff
    font-size: 15px
    text-align: center
    align-self: center
    display: flex
  
  .nav-bar-elements
    gap: 15px
    border-color: #ff5a00
    padding-left: 20px
    padding-right: 20px
    cursor: pointer

  .nav-bar-elements.active
    background-color: #FF9800
    border-radius: 28px
    transition: width 3s ease, opacity 3s ease out
  
  .na-bar-elements.nav-bar-text.nav-link.active
    .nav-bar-icon
      fill: white
  
  .nav-bar-toggler
    color: #fff

  .nav-link: active
    background-color: #ff9900

  .nav-bar-icon
    fill: #fff
    width: 20px
    height: 20px
    margin-right: 5px


@media (max-width: 991px) 
  #nav-bar
    background-color: transparent
    border-color: #ff5a00
    font-size: 18px

    .nav-bar-toggler
      color: #fff

    .nav-bar-text
      color: #3a3a3a
      text-align: left
      border-color: #ff5a00
      display: block
      border-radius: 20px
    
    .nav-bar-icon
      color: #ff5a00
    
    .nav-bar-elements
      display: block
      background-color: white
      border-width: 1px
      font-size: 18px
    
    .nav-bar-elements.active
      display: block
      color: white
      border-width: 1px
      background-color: #FF9800
      border-radius: 16px
      transition: width 3s ease, opacity 3s ease out
    
    .nav-bar-collapse
      margin-top: 10px
      display: block

  .nav-bar-container
    padding: 20px 5px
    background-color: #fff
    border-radius: 15px

.nav-bar-container
  padding: 0px 0px
  background-color: transparent
  border-radius: 15px

.loader
  border: 4px solid rgba(0, 0, 0, 0.1)
  border-left-color: #ffecd1
  border-radius: 50%
  width: 20px
  height: 20px
  animation: spin 1s linear infinite

@keyframes spin
  to
    transform: rotate(360deg)

.sending-code
  display: flex
  align-items: center